import React from "react"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import BrandLogo1 from "../../images/svg/brand-logo-1.svg"
import BrandLogo2 from "../../images/svg/brand-logo-2.svg"
import BrandLogo3 from "../../images/svg/brand-logo-3.svg"
import BrandLogo4 from "../../images/svg/brand-logo-4.svg"
import BrandLogo5 from "../../images/svg/brand-logo-5.svg"

const Client1 = () => {
  return (
    <>
      <Section>
        <Container>
          {/* section-wrap */}
          <div className="flex flex-col items-center gap-8 tablet:gap-14 laptop:gap-[82px] justify-center">
            {/* top-part */}
            <div
              data-aos="fade-in"
              className="flex flex-col items-center justify-center max-w-[588px] mx-auto"
            >
              <SectionTitle className="text-center">
                Our <span className="text text-primary-600">partners</span>
              </SectionTitle>
              <SectionText className="text-center !pb-0">
                A new normal that has evolved from generation X is on the runway
                heading towards a streamlined cloud solution.
              </SectionText>
            </div>
            {/* bottom-part */}
            <div className="">
              <div className="max-w-[1000px] mx-auto px-5 tablet:px-114 laptop:px-0">
                <div className="flex flex-wrap items-center justify-center gap-10 px-10 py-10 laptop:gap-14 bg-neutral-50 rounded-3xl">
                  <div data-aos="zoom-in">
                    <BrandLogo1></BrandLogo1>
                  </div>
                  <div data-aos="zoom-in">
                    <BrandLogo2></BrandLogo2>
                  </div>
                  <div data-aos="zoom-in">
                    <BrandLogo3></BrandLogo3>
                  </div>
                  <div data-aos="zoom-in">
                    <BrandLogo4></BrandLogo4>
                  </div>
                  <div data-aos="zoom-in">
                    <BrandLogo5></BrandLogo5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Client1
