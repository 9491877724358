import React from "react"
import { Link } from "gatsby"
import { Telephone, Envelope } from "react-bootstrap-icons"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import Button from "../../components/Button"
import TextSmall from "../../components/TextSmall"
import Input from "../../components/forms/Input"
import TextArea from "../../components/forms/TextArea"

const Contact3 = () => {
  return (
    <>
      <Section>
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12">
            {/* left-part */}
            <div className="col-span-6 pb-14 laptop:pb-0 laptop:pr-[112px]">
              <form className="flex flex-col gap-8">
                <div data-aos="fade-in" className="flex flex-col">
                  <SectionTitle className="">Contact us</SectionTitle>
                  <SectionText className="">
                    Collaboratively administrate empowered markets via
                    plug-and-play networks.
                  </SectionText>
                </div>
                {/* from */}
                <div className="flex flex-col gap-6 ">
                  <div className="flex flex-col gap-6 tablet:flex-row">
                    <Input
                      label="First name"
                      placeholder="John"
                      name="first-name"
                    />
                    <Input
                      label="Last name"
                      placeholder="Doe"
                      name="last-name"
                    />
                  </div>
                  <Input
                    type="email"
                    label="Email"
                    placeholder="johndoe@example.com"
                    name="email"
                  />
                  <TextArea label="Additional details" name="textarea" />
                  <Input
                    type="checkbox"
                    id="Checkbox3"
                    name="checkbox"
                    label={
                      <>
                        I agree to the{" "}
                        <Link to="#">
                          <span className="font-medium text-body-md text-primary-600">
                            privacy policy.
                          </span>{" "}
                        </Link>
                      </>
                    }
                  />
                </div>
                <Button type="submit" size={"xl"}>
                  Send message
                </Button>
              </form>
            </div>

            {/* right-part */}
            <div className="col-span-6">
              <div className="flex flex-col gap-8 tablet:gap-14">
                {/* cards */}
                <div className="flex flex-col gap-6 tablet:gap-12 tablet:flex-row">
                  <div className="flex gap-3">
                    <Telephone className="mt-1 text-primary-600 text-body-xl" />
                    {/* text */}
                    <div className="flex flex-col gap-2">
                      <SectionText className="!font-medium !text-neutral-700 !pb-0">
                        Call us
                      </SectionText>
                      <a href="tel:+8 2233 4111 00">
                        <TextSmall className="!font-medium !text-primary-600">
                          +8 2233 4111 00
                        </TextSmall>
                      </a>
                    </div>
                  </div>
                  <div className="flex gap-3">
                    <Envelope className="mt-1 text-primary-600 text-body-xl" />
                    {/* text */}
                    <div className="flex flex-col gap-2">
                      <SectionText className="!font-medium !text-neutral-700 !pb-0">
                        Email us
                      </SectionText>
                      <a href="mailto:support@kloft.com">
                        <TextSmall className="!font-medium !text-primary-600">
                          support@kloft.com
                        </TextSmall>
                      </a>
                    </div>
                  </div>
                </div>

                <div>
                  <iframe
                    title="ContactMap"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d24186.746374417165!2d-74.163347!3d40.732471!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb4ca1d332f6f0198!2sFornos%20of%20Spain!5e0!3m2!1sen!2sid!4v1655271871497!5m2!1sen!2sid"
                    width="100%"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Contact3
