import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Section from "../../components/Section"
import Container from "../../components/Container"
import SectionTitle from "../../components/SectionTitle"
import SectionText from "../../components/SectionText"
import ListItem from "../../components/ListItem"

const Feature1 = ({ className }) => {
  return (
    <>
      <Section className={`bg-primary-25 ${className}`}>
        <Container>
          {/* section-wrap */}
          <div className="grid items-center justify-center grid-cols-1 laptop:grid-cols-12 ">
            {/* left-part */}
            <div className="relative laptop:col-span-5">
              <StaticImage
                src="../../images/feature-1.png"
                alt=""
                className="z-20 w-full rounded-3xl"
              />
              {/* shapes */}
              <div className="absolute top-0 left-0 z-30 -translate-x-1/4 -translate-y-1/4 ">
                <div
                  className="tablet:w-[100px] w-16 h-16 tablet:h-[100px] bg-secondary-100"
                  data-aos="fade-down-right"
                >
                  <div className="absolute z-30 bottom-full right-full  w-[48px] h-[48px] bg-neutral-100"></div>
                </div>
              </div>
              <div
                className="absolute 
                 right-[15%] z-10 -translate-y-1/2"
              >
                <div
                  className=" tablet:w-[142px] w-24 h-24 tablet:h-[142px] bg-primary-100 rounded-full"
                  data-aos="fade-down"
                ></div>
              </div>
            </div>
            {/* right-part */}
            <div className="pt-20 laptop:col-span-7 laptop:pt-0 laptop:pl-24">
              <div className="wrap">
                <div data-aos="fade-up">
                  <SectionTitle>
                    Boost your business with robust{" "}
                    <span className="text text-primary-600">features</span>
                  </SectionTitle>
                  <SectionText className="max-w-[494px]">
                    Capitalize on low hanging fruit to identify a ballpark value
                    added activity to beta test.
                  </SectionText>
                </div>
                <div
                  data-aos="fade-up"
                  className="flex flex-col justify-start gap-4 Features-items max-w-[424px]"
                >
                  <ListItem>
                    User generated content in real-time will have.
                  </ListItem>
                  <ListItem>
                    Capitalize on low hanging fruit to identify a ballpark.
                  </ListItem>
                  <ListItem>
                    Bring to the table win-win survival strategies to ensure
                    proactive domination.
                  </ListItem>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
    </>
  )
}

export default Feature1
