import React from "react"
import { GatsbySeo } from "gatsby-plugin-next-seo"

import PageWrapper from "../components/PageWrapper"
import InnerHero from "../components/InnerHero"
import Feature4 from "../sections/features/Feature4"
import Feature1 from "../sections/features/Feature1"
import Team1 from "../sections/teams/Team1"
import Client1 from "../sections/clients/Client1"
import Contact3 from "../sections/contacts/Contact3"

const AboutPage1 = ({ data }) => {
  return (
    <>
      <PageWrapper
        footerConfig={{
          hasMiddleSocial: true,
          bottomWrapperClassName: "!justify-center",
          bottomClassName: "bg-primary-25",
          className: "bg-neutral-100",
        }}
      >
        <GatsbySeo
          title={`Kloft - About 01`}
          description={`We serve globally to modernize static sites to take advantage of the blazing speeds of JAMStack while eliminating monthly maintenance and even probably, server costs.`}
        />
        <InnerHero
          title="About 01"
          text="Nanotechnology immersion along the information highway will close the loop on focusing solely on the bottom line."
        />
        <Feature4 />
        <Feature1 />
        <Team1 className="!bg-white" />
        <Client1 />
        <Contact3 />
      </PageWrapper>
    </>
  )
}

export default AboutPage1
